export const municipios = ["MTY", "GPE", "SPGG", "SC"];

export const municipiosTables = [
  {
    id: 1,
    clave: "MTY",
    nombre: "Monterrey",
    displayName: "Monterrey",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: true,
  },
  {
    id: 2,
    clave: "GPE",
    nombre: "Guadalupe",
    displayName: "Guadalupe",
    cuencaBaja: true,
    cuencaAlta: false,
    cuencaMedia: true,
  },
  {
    id: 3,
    clave: "SPGG",
    nombre: "San Pedro",
    displayName: "San Pedro Garza García",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: true,
  },
  {
    id: 4,
    clave: "SC",
    nombre: "Santa Catarina",
    displayName: "Santa Catarina",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: true,
  },
  {
    id: 5,
    clave: "JUA",
    nombre: "Juarez",
    displayName: "Juárez",
    cuencaAlta: true,
    cuencaBaja: true,
    cuencaMedia: false,
  },
  {
    id: 6,
    clave: "ART",
    nombre: "Arteaga",
    displayName: "Arteaga",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: false,
  },
  {
    id: 7,
    clave: "RA",
    nombre: "Ramos Arizpe",
    displayName: "Ramos Arizpe",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: false,
  },
  {
    id: 8,
    clave: "SA",
    nombre: "Santiago",
    displayName: "Santiago",
    cuencaAlta: true,
    cuencaBaja: false,
    cuencaMedia: false,
  },
  {
    id: 9,
    clave: "CJ",
    nombre: "Cadereyta Jimenez",
    displayName: "Cadereyta Jiménez",
    cuencaAlta: false,
    cuencaBaja: true,
    cuencaMedia: false,
  },
  {
    id: 10,
    clave: "GC",
    nombre: "Garcia",
    displayName: "García",
    cuencaAlta: false,
    cuencaBaja: false,
    cuencaMedia: false,
  },
  {
    id: 11,
    clave: "TODOS",
    nombre: "Todos",
    displayName: "Todos",
    cuencaAlta: true,
    cuencaBaja: true,
    cuencaMedia: true,
  },
];

const cuencasDictionary = [
  {
    cuencaValor: "baja",
    cuencaNombre: "Cuenca baja",
    tableNameBiodiversidad: "biodiversidad_RH24Bf_b",
  },
  {
    cuencaValor: "media",
    cuencaNombre: "Cuenca media",
    tableNameBiodiversidad: "biodiversidad_RH24Bf_m",
  },
  {
    cuencaValor: "alta",
    cuencaNombre: "Cuenca alta",
    tableNameBiodiversidad: "biodiversidad_RH24Bf_a",
  },
];

export function getTableName(municipioCode) {
  if (!municipioCode) {
    return "Unknown Municipio";
  }
  const result = municipiosTables.filter((m) => m.clave === municipioCode);

  return result.length > 0 ? result[0].nombre : "Unknown Municipio";
}

function getCuencaName(cuencaCode) {
  var result = cuencasDictionary.filter((c) => {
    return c.cuencaValor === cuencaCode;
  });

  return result[0].cuencaNombre;
}

function getBiodiversidadTable(cuencaCode) {
  var result = cuencasDictionary.filter((c) => {
    return c.cuencaValor === cuencaCode;
  });

  return result[0].tableNameBiodiversidad;
}

export function filteredMunicipiosByCuenca(cuencaCode) {
  const cuencaMap = {
    baja: "cuencaBaja",
    media: "cuencaMedia",
    alta: "cuencaAlta",
  };

  const cuencaField = cuencaMap[cuencaCode];

  return municipiosTables.filter((municipio) => municipio[cuencaField]);
}

const rpcParams = {
  get_biodiversidad_points: {
    data_name: (filters) => getBiodiversidadTable(filters.cuenca),
    municipio_param: (filters) => getTableName(filters.municipio),
    amenazada_param: (filters) => filters.amenazada,
    rh_param: (filters) => filters.rh,
    grupo_param: null,
  },
  get_suelo_y_vegetacion: {
    municipio_param: (filters) => getTableName(filters.municipio),
  },
  get_denue_points: {
    municipio_param: (filters) => getTableName(filters.municipio),
    cuenca_param: (filters) => getCuencaName(filters.cuenca),
  },
  get_biodiversidad_summary_with_filters: {
    data_name: (filters) => getBiodiversidadTable(filters.cuenca),
    municipio_param: (filters) => getTableName(filters.municipio),
    amenazada_param: () => 0,
    rh_param: (filters) => filters.rh,
    grupo_param: null,
  },
  get_suelo_summary_with_filters: {
    municipio_param: (filters) => getTableName(filters.municipio),
  },
  get_denue_summary_with_filters: {
    municipio_param: (filters) => getTableName(filters.municipio),
    cuenca_param: (filters) => getCuencaName(filters.cuenca),
  },
};

export function getParamsForRpc(rpcName, filters) {
  const paramsTemplate = rpcParams[rpcName];
  if (!paramsTemplate) {
    throw new Error(`No parameters defined for RPC function: ${rpcName}`);
  }

  const params = {};
  for (const key in paramsTemplate) {
    const value = paramsTemplate[key];
    params[key] = typeof value === "function" ? value(filters) : value;
  }

  return params;
}
