import React, { useRef, useEffect, useContext, useState } from "react";
import "./PointsLayerOverlay.css";
import { GeoJSON } from "react-leaflet";
import { useGetPolygonParameter } from "../../../hooks/useGetPolygonParameter.js";
import { useFetchTooltipData } from "../../../hooks/useFetchTooltipData.js";
import { FiltersContext } from "../../../contexts/FiltersContext.js";
import { createPointToLayer, polygonStyle } from "../helpers/colorUtils.js";

const PointsLayerOverlay = ({ setLoading }) => {
  const polygonRef = useRef(null);
  const activeLayerRef = useRef(null);
  const fetchedTooltipData = useRef({});
  const { filters } = useContext(FiltersContext);

  const [clickedId, setClickedId] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  const { fetchTooltipData, loading: tooltipLoading } = useFetchTooltipData();

  const rpcName = (() => {
    switch (filters.puntos) {
      case "biodiversidad":
        return "get_biodiversidad_points";
      case "suelo":
        return "get_suelo_y_vegetacion";
      case "denue":
        return "get_denue_points";
      default:
        return "get_biodiversidad_points";
    }
  })();

  const { polygon, loading } = useGetPolygonParameter(filters, rpcName);

  const onEachFeature = (feature, layer) => {
    layer.on("click", async (e) => {
      const { id } = feature.properties;
      const { latlng } = e;

      if (clickedId === id && fetchedTooltipData.current[id]) {
        return;
      }

      setClickedId(id);

      if (activeLayerRef.current) {
        try {
          activeLayerRef.current.unbindTooltip();
        } catch (error) {
          console.warn("Error unbinding tooltip:", error);
        }
        activeLayerRef.current = null;
      }

      try {
        layer
          .bindTooltip(`<div><strong>Cargando...</strong> </div>`, {
            direction: "auto",
          })
          .openTooltip(latlng);
      } catch (error) {
        console.warn("Error binding tooltip:", error);
        return;
      }

      activeLayerRef.current = layer;

      if (!fetchedTooltipData.current[id]) {
        const tooltipData = await fetchTooltipData(filters, id);
        fetchedTooltipData.current[id] = tooltipData || null;
      }

      const data = fetchedTooltipData.current[id];
      const content = data
        ? filters.puntos === "biodiversidad"
          ? `
          <div>
            <strong>Fecha:</strong> ${data.observed_o || "N/A"}<br />
            <strong>Nombre científico:</strong> ${
              data.scientific || "N/A"
            }<br />
            <strong>Nombre común:</strong> ${data.common_nam || "N/A"}<br />
            <strong>Grupo:</strong> ${data.grupo || "N/A"}<br />
          </div>
          `
          : filters.puntos === "suelo"
          ? `
          <div>
            <strong>Descripción:</strong> ${data.descrip || "N/A"}<br />
            <strong>Área del polígono:</strong> ${
              data.shape_area || "N/A"
            }<br />
            <strong>Grupo:</strong> ${data.gpo_fx || "N/A"}<br />
          </div>
          `
          : filters.puntos === "denue"
          ? `
          <div>
            <strong>Nombre del negocio:</strong> ${data.nombre || "N/A"}<br />
            <strong>Sector:</strong> ${data.sector || "N/A"}<br />
            <strong>Categoría:</strong> ${data.categoria || "N/A"}<br />
          </div>
          `
          : `<div><strong>ID:</strong> ${id}<br />No hay datos.</div>`
        : `<div><strong>ID:</strong> ${id}<br />No hay datos.</div>`;

      setTooltipData(content);

      if (activeLayerRef.current) {
        try {
          activeLayerRef.current
            .bindTooltip(content, { direction: "auto" })
            .openTooltip();
        } catch (error) {
          console.warn("Error updating tooltip content:", error);
        }
      }
    });
  };

  useEffect(() => {
    setLoading(loading || tooltipLoading);
  }, [loading, setLoading, tooltipLoading]);

  useEffect(() => {
    const layer = polygonRef.current;
    if (layer) {
      layer.clearLayers();
      if (polygon) {
        layer.addData(polygon);
      }
    }
  }, [polygon]);

  return (
    <>
      {polygon && (
        <GeoJSON
          data={polygon}
          ref={polygonRef}
          style={polygonStyle}
          pointToLayer={createPointToLayer(rpcName)}
          onEachFeature={onEachFeature}
          key={rpcName}
        />
      )}
    </>
  );
};

export default PointsLayerOverlay;
