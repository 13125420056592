import { useState } from "react";
import { supabaseClient } from "../config/supabase";

export const useFetchTooltipData = () => {
  const [loading, setLoading] = useState(false);

  const fetchTooltipData = async (filters, id) => {
    setLoading(true);

    const tableName = (() => {
      switch (filters.puntos) {
        case "suelo":
          return "syv_TU_RSC";
        case "denue":
          return "DENUE2024_RH24Bf_cats";
        case "biodiversidad":
        default:
          return getBiodiversidadTable(filters.cuenca);
      }
    })();

    if (!tableName) {
      console.error("Table name not found for cuenca:", filters.cuenca);
      setLoading(false);
      return null;
    }

    try {
      const columns = (() => {
        switch (filters.puntos) {
          case "suelo":
            return "id, descrip, shape_area, gpo_fx";
          case "denue":
            return "id, nombre, sector, categoria";
          case "biodiversidad":
          default:
            return "id, observed_o, scientific, common_nam, grupo, url";
        }
      })();
      const { data, error } = await supabaseClient
        .from(tableName)
        .select(columns)
        .eq("id", id);

      if (error) throw error;

      return data[0] || null;
    } catch (error) {
      console.error("Error fetching tooltip data:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { fetchTooltipData, loading };
};

const getBiodiversidadTable = (cuencaCode) => {
  const cuencasDictionary = [
    { cuencaValor: "baja", tableNameBiodiversidad: "biodiversidad_RH24Bf_b" },
    { cuencaValor: "media", tableNameBiodiversidad: "biodiversidad_RH24Bf_m" },
    { cuencaValor: "alta", tableNameBiodiversidad: "biodiversidad_RH24Bf_a" },
  ];

  const result = cuencasDictionary.find((c) => c.cuencaValor === cuencaCode);
  return result?.tableNameBiodiversidad || null;
};
