import React, { useState } from "react";

export default function DetailsAccordion({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="space-y-4 w-full max-w-[600px] mx-auto">
      {data.map((item, index) => (
        <div
          key={index}
          className={`border-b ${
            activeIndex === index ? "border-blueRio" : "border-blueRio"
          } rounded w-full`}
        >
          <div
            className={`flex justify-between items-center p-4 cursor-pointer bg-white ${
              activeIndex === index ? "text-blueRio" : "text-blueRio"
            } w-full`}
            onClick={() => toggleAccordion(index)}
          >
            <span className=" font-semibold truncate flex-grow">
              {toTitleCase(item.title)}
            </span>
            <span
              className={`transform transition-transform ${
                activeIndex === index ? "rotate-180" : "rotate-0"
              }`}
            >
              ▼
            </span>
          </div>
          {activeIndex === index && (
            <div className="p-4 bg-white text-blueRio max-h-[500px] overflow-y-auto ">
              {Array.isArray(item.content) ? (
                <ol className="list-decimal list-inside">
                  {item.content.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ol>
              ) : (
                <p>{item.content}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
