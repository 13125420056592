import React, { useEffect, useState } from "react";
import Navigation from "./components/Navigation/Navigation";
import Dashboard from "./components/Dashboard/Dashboard";
import ProjectDetailsSection from "./components/ProjectDetailsSection/ProjectDetailsSection";
import Descargas from "./components/Descargas/Descargas";
import RegistroDescargas from "./components/Descargas/RegistroDescargas";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    const hasRegistered = localStorage.getItem("hasRegistered") === "true";
    setIsRegistered(hasRegistered);
  }, []);

  const handleRegistrationSuccess = () => {
    localStorage.setItem("hasRegistered", "true");
    setIsRegistered(true);
  };

  return (
    <div className="overflow-hidden">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigation />
              <ProjectDetailsSection />
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="/descargas"
          element={
            <>
              <Navigation />
              {isRegistered ? (
                <Descargas />
              ) : (
                <RegistroDescargas onSuccess={handleRegistrationSuccess} />
              )}
            </>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
