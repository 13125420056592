import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { supabaseClient } from "../../config/supabase";

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre es obligatorio"),
  email: Yup.string()
    .email("Correo inválido")
    .required("Correo electrónico es obligatorio"),
  uso: Yup.string().required("Por favor, selecciona un uso"),
  detalles: Yup.string().required("Por favor, proporciona detalles del uso"),
});

const RegistroDescargas = ({ onSuccess }) => {
  return (
    <Formik
      initialValues={{
        nombre: "",
        email: "",
        uso: "",
        detalles: "",
        institucion: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const { error } = await supabaseClient
            .from("registro_descargas")
            .insert([{ ...values, accessed_at: new Date() }]);

          if (error) {
            console.error("Error adding data:", error);
          } else {
            localStorage.setItem("hasRegistered", "true");
            resetForm();
            if (onSuccess) onSuccess();
          }
        } catch (error) {
          console.error("Submission error:", error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="max-w-md mx-auto p-4 bg-white rounded shadow-md mt-6">
          <div className="mb-4">
            <label htmlFor="nombre" className="block text-gray-700">
              Nombre*
            </label>
            <Field
              name="nombre"
              type="text"
              className="mt-1 p-2 w-full border rounded"
            />
            <ErrorMessage
              name="nombre"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700">
              Correo Electrónico*
            </label>
            <Field
              name="email"
              type="email"
              className="mt-1 p-2 w-full border rounded"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="uso" className="block text-gray-700">
              Uso que daré a los datos*
            </label>
            <Field
              as="select"
              name="uso"
              className="mt-1 p-2 w-full border rounded"
            >
              <option value="">Seleccione una opción</option>
              <option value="Investigación académica">
                Investigación académica
              </option>
              <option value="Investigación ciudadana">
                Investigación ciudadana
              </option>
              <option value="Diseño de política pública">
                Diseño de política pública
              </option>
              <option value="Otro">Otro</option>
            </Field>
            <ErrorMessage
              name="uso"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="detalles" className="block text-gray-700">
              Detalles del uso*
            </label>
            <Field
              name="detalles"
              as="textarea"
              rows="3"
              className="mt-1 p-2 w-full border rounded"
              placeholder="Describe los temas o objetivos"
            />
            <ErrorMessage
              name="detalles"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="institucion" className="block text-gray-700">
              Institución a la que perteneces
            </label>
            <Field
              name="institucion"
              type="text"
              className="mt-1 p-2 w-full border rounded"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blueRio text-white p-2 rounded"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Enviando..." : "Enviar"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default RegistroDescargas;
