export const accordionDataProblematicas = [
  {
    title: "PROBLEMÁTICAS PRINCIPALES",
    content: [
      "Tiraderos de basura y escombro vinculados a poblaciones con derechos vulnerados y a los límites intermunicipales.",
      "Abandono negligente de residuos de la construcción y demolición asociados a obras viales recientes y a infraestructuras averiadas.",
      "Descargas de aguas negras.",
      "Población vecina excluida del servicio regular de recolección de residuos.",
      "Presencia y avance de grandes parches de vegetación invasora, principalmente carrizo.",
    ],
  },
  {
    title: "LA MAYORÍA DE LA VEGETACIÓN GENERA BENEFICIOS.",
    content:
      "La mayoría de las comunidades vegetales son propias de la región. La vegetación secundaria y algunas especies exóticas retienen los taludes evitando deslaves. Se encuentran también brotes y áreas extensas con plantas invasoras reconocidas como nocivas para los ecosistemas mexicanos. Las crecidas del río funcionan como factor de selección al remover la vegetación establecida sobre sedimentos.",
  },
  {
    title: "USOS HUMANOS DEL RÍO INVISIBILIZADOS Y SIN ATENCIÓN GUBERNAMENTAL.",
    content:
      "Caminos que permiten cruzar, espacios de apreciación-recreación, vivienda aledaña y pernocta o refugio temporal son algunos de los usos humanos que el río permite y que carecen de medidas de seguridad adecuadas. El RSC tomado en cuenta como un hito del territorio, es una oportunidad para implementar la Estrategia Nacional de Ordenamiento Territorial con un sentido ecológico, de seguridad hídrica y cambio climático.",
  },
  {
    title:
      "PERSPECTIVA DE DERECHOS AMBIENTALES AUSENTE EN LA PLANEACIÓN URBANA DE LA CIUDAD.",
    content:
      "Los espacios naturales están en constante riesgo de afectación por las obras civiles y las dinámicas urbanas. Los ayuntamientos y el gobierno estatal desconocen los activos ambientales y cómo cuidarlos desde sus propios marcos regulatorios; en contraste promueven un urbanismo que arriesga la ciudadanía y la priva de un medio ambiente sano.",
  },
  {
    title: "EL RSC ES UN REFUGIO PARA LA DIVERSIDAD BIOLÓGICA.",
    content:
      "Interconecta Áreas Naturales Protegidas permitiendo conservar los servicios ambientales que aporta la biodiversidad. El RSC es casa de al menos 40 especies amenazadas (NOM-059-SEMARNAT-2010 y Lista roja de UICN) que han sido identificadas en un radio de 1 km alrededor del río; también es destino por temporada para especies migratorias.",
  },
  {
    title: "SOLUCIONES BASADAS EN LA NATURALEZA.",
    content:
      "Resaltamos la necesidad de restauración y conservación en todos los segmentos de la cuenca del RSC rehabilitando las funciones hidrológicas del ecosistema. Analizar el cambio en las comunidades vegetales y en los tipos de suelo por efecto del paso de la tormenta Alberto, permitirá determinar puntos prioritarios para intervenir con zonas inundables, humedales de saneamiento e infiltración, jardines de lluvia y otras soluciones basadas en la naturaleza.",
  },
];

export const accordionDataInvestigacion = [
  {
    title: "Hábitat íntegro y servicios ambientales de calidad",
    content: [
      "Asegurar fuentes de agua limpia en previsión de un caudal ecológico.",
      "Detener el ingreso de contaminantes al agua y al suelo.",
      "Determinar la capacidad de carga para diferentes actividades y dinámicas (vg. actividades de esparcimiento y apreciación del paisaje).",
    ],
  },
  {
    title: "Estructura y función hidráulica",
    content: [
      "Reducir los riesgos asociados a fenómenos hidrometeorológicos.",
      "Identificar e implementar soluciones basadas en la naturaleza para recuperar la funcionalidad hidrológica por zona.",
      "Reducir la acumulación de sedimentos.",
      "Acoplar trabajo en la cuenca alta y trabajo en el tramo urbano.",
      "Multi-localizar las soluciones, descentralizar la función hidrológica para acoplarla a los ecosistemas locales: función y estructura distribuida.",
    ],
  },
  {
    title: "Comunidades vegetales nativas",
    content: [
      "Promover una composición vegetal propia de la región y capaz de mejorar la calidad de los servicios ambientales locales.",
      "Controlar los brotes de vegetación invasora catalogada como prioridad de manejo en el país y en ecosistemas afines.",
    ],
  },
  {
    title: "Tejido social",
    content: [
      "Considerar, valorar y promover el tejido social de las comunidades vecinas del río en relación con el territorio que habitan, con especial atención en los asentamientos autogestivos y en polígonos prioritarios para la igualdad e inclusión.",
      "Asegurar que la mejora del entorno ecológico en el espacio público proteja a la población local sin promover su desplazamiento.",
      "Valorar las capacidades locales y promover el cuidado comunitario e interinstitucional de los ecosistemas locales.",
    ],
  },
  {
    title: "Fauna sujeta a protección especial",
    content: [
      "Asegurar hábitat propicio para la fauna sujeta a conservación.",
      "Asegurar la conectividad entre ANPs.",
      "Propiciar una sana coexistencia ciudadanía - vida silvestre.",
    ],
  },
  {
    title: "Identidad regia",
    content: [
      "Consolidar al Río como un elemento vivo e icónico de la ciudad de Monterrey (múltiples territorios, una identidad compartida, un flujo, una cuenca).",
      "Valorar la diversidad de relaciones entre el río y sus vecinos humanos.",
    ],
  },
];
