import DetailsAccordion from "../components/ProjectDetailsSection/DetailsAccordion/DetailsAccordion";
import {
  accordionDataInvestigacion,
  accordionDataProblematicas,
} from "../components/ProjectDetailsSection/helpers/accordionData";

export const tabsRio = [
  {
    id: 0,
    label: "Proyecto",
    content: (
      <p>
        SOSAC trabaja desde 2017 en el Río Santa Catarina involucrando a la
        ciudadanía en el mapeo de problemáticas ambientales y en la generación
        común de soluciones desde el cuidado del ecosistema urbano. Con las
        primeras visitas resultó claro que posterior al huracán Alex en 2010, el
        caudal canalizado del río comenzó a alojar un bosque típico de río en
        avance (sucesión biológica). <br></br>
        <br></br>Desde SOSAC, impulsamos la investigación-acción con el objetivo
        de cuidar y fomentar los servicios ambientales presentes en la ciudad,
        para ello caracterizamos el territorio y exploramos las relaciones
        ecológicas que permiten asegurar los derechos ambientales de la
        ciudadanía. Con estos primeros hallazgos podemos definir una ruta de
        acción diferenciada para cada segmento de la cuenca del río y articular
        acciones entre los distintos niveles de gobierno como modelo para
        asegurar cuerpos de agua saludables en la ciudad.
      </p>
    ),
    images: ["cont1.jpg", "cont2.jpg", "cont3.jpg", "cont4.jpg"],
  },
  {
    id: 1,
    label: "Investigación",
    content: (
      <div>
        <p>
          Entre septiembre de 2023 y abril de 2024, SOSAC coordinó un programa
          piloto para el estudio del tramo urbano del Río Santa Catarina (31
          Km). <br></br>
          <br></br>El tramo comprende desde la entrada del parque La Huasteca en
          el municipio de Santa Catarina, hasta el puente vehicular de Israel
          Cavazos en el municipio de Guadalupe. Entre las acciones que
          realizamos están:
        </p>
        <ol className="list-decimal list-inside mt-2">
          <li>
            Recolección y separación de más de 74 ton de residuos abandonados en
            el río para analizar de dónde viene la basura.
          </li>
          <li>
            Mapeamos qué hay en el río: suelo y la vegetación existente y para
            realizar estrategias de reducción de riesgos y de mejora del
            ecosistema para cada condición.
          </li>
          <li>
            Entrevistamos a la población vecina para entender su conexión con el
            río y documentar las dinámicas positivas y capacidades presentes
            para cuidarlo y mejorarlo.{" "}
          </li>
        </ol>
      </div>
    ),
    images: ["inv1.jpg", "inv2.jpg", "inv3.jpg", "inv4.jpg", "inv5.jpg"],
  },
  {
    id: 2,
    label: "Hallazgos",
    content: (
      <div className="w-full">
        <DetailsAccordion data={accordionDataProblematicas} />
      </div>
    ),
    images: ["foto1.jpg", "foto2.jpg", "foto3.jpg", "foto4.jpg", "foto5.jpg"],
  },
  {
    id: 3,
    label: "Impacto",
    content: (
      <div className="w-full">
        <DetailsAccordion data={accordionDataInvestigacion} />
      </div>
    ),
    images: [
      "habitats.jpg",
      "estructurahid.jpg",
      "vegetacion.jpg",
      "tejidosocial.jpg",
      "fauna.jpg",
    ],
  },
];
