import L from "leaflet";

export const getColorForPolygon = (polygonName, feature) => {
  switch (polygonName) {
    case "tres_segmentos":
      if (feature.properties.segmento === "Cuenca alta") return "purple";
      else if (feature.properties.segmento === "Cuenca media") return "green";
      else return "red";
    case "anps_rh24Bf":
      return "#809c13";
    case "buffer_1km_tu":
      return "#03396c";
    case "get_biodiversidad_points":
      return "#809c1395";
    case "get_denue_points":
      if (feature.properties.categoria === "Aceites y productos químicos")
        return "#b7a647";
      else if (
        feature.properties.categoria === "Materiales de construcción y escombro"
      )
        return "#233b6b";
      else if (feature.properties.categoria === "Gestión de residuos")
        return "#86643f";
      else if (feature.properties.categoria === "Llantas y autopartes")
        return "#55575a";
      else return "#999999";
    case "get_suelo_y_vegetacion":
      return "#f9a03f";
    default:
      return "#6497b1";
  }
};

export const createPointToLayer = (rpcName) => {
  return (feature, latlng) => {
    const color = getColorForPolygon(rpcName, feature);

    const denueIconSVG = `
      <svg width="25" height="25" viewBox="0 0 24.97 24.97" xmlns="http://www.w3.org/2000/svg">
        <circle fill="${color}" cx="12.48" cy="12.48" r="12.48"/>
        <g>
          <line stroke="#fff" stroke-miterlimit="10" stroke-width="3px" x1="18.96" y1="6.01" x2="6.01" y2="18.96"/>
          <line stroke="#fff" stroke-miterlimit="10" stroke-width="3px" x1="18.96" y1="18.96" x2="6.01" y2="6.01"/>
        </g>
      </svg>
    `;

    const defaultIconSVG = `
      <svg width="20" height="20" viewBox="0 0 31.41 27.2" xmlns="http://www.w3.org/2000/svg">
        <polygon 
          points="15.7 2 1.73 26.2 29.68 26.2 15.7 2"
          fill="${color}"
          stroke="${color}"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    `;

    const iconSVG =
      rpcName === "get_denue_points" ? denueIconSVG : defaultIconSVG;

    const customIcon = L.divIcon({
      className: "custom-icon",
      html: iconSVG,
      iconSize: [20, 20],
      iconAnchor: [12.5, 12.5],
      popupAnchor: [0, -12.5],
    });

    return L.marker(latlng, { icon: customIcon });
  };
};

export const polygonStyle = (feature) => {
  const { gpo_fx } = feature.properties;

  let fillColor;
  switch (gpo_fx) {
    case "Comunidades vegetales por conservar":
      fillColor = "#f4a179";
      break;
    case "Comunidades vegetales por investigar":
      fillColor = "#f4e181";
      break;
    case "Comunidades vegetales por conservar":
      fillColor = "#a1d183";
      break;
    case "Tipo de suelo":
      fillColor = "#7ccdee";
      break;
    case "Uso de suelo":
      fillColor = "#c4b4a9";
      break;
    default:
      fillColor = "#cccccc";
  }

  return {
    color: "#333",
    weight: 0.5,
    fillColor: fillColor,
    fillOpacity: 0.8,
  };
};
